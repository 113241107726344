@import url("https://fonts.googleapis.com/css2?family=Carattere&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap");

:root {
  --header-height: 3rem;
  --nav-width: 68px;

  --cyan-50: #ecfeff;
  --cyan-100: #cffafe;
  --cyan-200: #a5f3fc;
  --cyan-300: #67e8f9;
  --cyan-400: #22d3ee;
  --cyan-500: #06b6d4;
  --cyan-600: #0891b2;
  --cyan-700: #0e7490;
  --cyan-720: #0e74908a;
  --cyan-800: #155e75;
  --cyan-900: #164e63;
  --cyan-950: #083344;

  --color-gradient: linear-gradient(
    202deg,
    rgba(34, 211, 238, 1) 0%,
    rgba(14, 116, 144, 1) 50%,
    rgba(8, 51, 68, 1) 100%
  );
}

body {
  background-color: #fcffe9;
  /* background-color: #164e63; */
}

h1,
h2,
h3,
.graph,
.company {
  font-family: "Carattere", cursive;
  font-weight: 400;
  font-style: normal;
  color: black;
}

h3 {
  font-weight: 600;
}

h4,
.quotes {
  font-family: "Tilt Neon", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "XROT" 0, "YROT" 0;
}

.about__me {
  margin: 3rem 3rem 3rem 3rem;
  letter-spacing: 1px;
  line-height: 28px;
  text-align: center;
}

.myServicesTitle {
  font-family: "Neonderthaw", cursive;
  font-weight: 400;
  font-style: normal;

  animation: flicker 1.5s infinite alternate;
  color: #fff;
}

button.btn-close {
  background-color: white;
}

.myServiceTitle {
  font-family: "Neonderthaw", cursive;
  font-weight: 400;
  font-style: normal;

  position: relative;
  top: 2.5rem;
}

/* Advertise **/
.goShirt:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

/* Subscribe style begins */

.subscribe {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.subscribe.show {
  display: block;
}

.subscribe-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #020202;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 40%;
}

.btn__subscribe__container {
  align-self: center;
}

.btn-subscribe {
  margin-bottom: 8px;
  max-width: 100px;
  border-radius: 5px;
  background-color: #000;
  color: rgb(255, 239, 239);
}

.btn-nope:hover {
  background-color: black;
  border-radius: 2px;
}

.btn-nope {
  border: none;
  background-color: transparent;
}

.exit {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.exit:hover,
.exit:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Subscribe style emds */

/* Product styles begin */

.product-card-container {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 2rem;
}

.product-card {
  width: 240px;
  /* border: 1px solid #ddd; */
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: inherit;
}

.product-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* aspect-ratio: 1/1; */
  padding: 1rem;
  /* background-color: #f8f9fa; */
  background-color: inherit;
}

.product-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

/* Product styles end */

/* Flickering animation */
@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 254, 254, 0.37);
  }
  100% {
    box-shadow: 0 0 0 80px rgba(0, 0, 0, 0);
  }
}

.product__zoom_img {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background-color: #faf9f6;
}

.order__number {
  font-family: "Reddit Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: italic;
  font-size: 25px;
}

.logo {
  width: 18rem;
}

html {
  --line-color: #e5e5e5;
}

main {
  min-height: 80vh;
}

.rating span {
  margin: 0.2rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  text-align: center;
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  text-align: center;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.review button {
  margin-top: 10px;
}

/*Update Button*/
.update:hover {
  color: var(--cyan-400);
}

.back:hover {
  background-color: black;
  color: white;
}

.active > .page-link,
.page-link.active {
  color: black;
  background-color: gray;
  border-color: var(--cyan-50);
}

.page-link {
  color: black;
}

.page-link:hover {
  color: var(--cyan-900);
}

.search {
  margin-left: 5px;
  border: none;
}

.search:hover {
  outline: 1px solid var(--cyan-700);
}

/*SERVICES PAGE*/

.grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Evenly distribute columns */
  gap: 10px;
  background-color: transparent;
  padding: 10px;
  color: rgb(11, 18, 21);
  font-family: "Carattere", cursive;
}

.grid-container > div {
  text-align: center;
  padding: 20px;
  font-size: 1.5rem;
  border-radius: 5px;
}

.theServiceTitle {
  margin-top: 3rem;
}

.info {
  cursor: pointer;
}

.gallery {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

section {
  margin-bottom: 300px;
}

.inspired {
  text-decoration: none;
}

.snapCam {
  display: flex;
  flex-direction: column;
}

.blink {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: black !important;
}

.privacy-terms {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.privacy-terms-icon-before {
  margin-left: 1px;
  position: relative;
  top: 2px;
}

.privacy-terms-icon-after {
  margin-left: 1px;
  position: relative;
  bottom: 2px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 3rem 0 2rem 0;
}

.gram {
  height: 30px;
  width: 30px;
  color: black;
}

.twitter {
  height: 30px;
  width: 30px;
  color: black;
  margin-left: 5px;
}

.fbook {
  height: 30px;
  width: 30px;
  color: black;
}

.linkn {
  height: 30px;
  width: 30px;
  color: black;
}

.twitt {
  height: 23px;
  width: 23px;
  color: black;
  margin-left: 5px;
}

.graphic__cube {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.toggle {
  border: none;
  box-shadow: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

/* Email Page */

.form {
  width: 340px;
  height: 575px;
  background: #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #000;
  margin: calc(50vh - 220px) auto;
  padding: 20px 30px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  position: relative;
}

.contact__jrome {
  margin: 10px 0;
  padding-bottom: 10px;
  width: 180px;
  color: #78788c;
  border-bottom: 3px solid #78788c;
}

input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
  border-bottom: 2px solid #bebed2;
}
input:focus {
  border-bottom: 2px solid #78788c;
}
p:before {
  content: attr(type);
  display: block;
  margin: 28px 0 0;
  font-size: 14px;
  color: #5a5a5a;
}
.message {
  float: right;
  padding: 8px 12px;
  margin: 8px 0 0;
  border: 2px solid #78788c;
  background: 0;
  color: #5a5a6e;
  cursor: pointer;
  transition: all 0.3s;
}
button:hover {
  background: #78788c;
  color: #fff;
}
.hit__me {
  content: "Hi";
  position: absolute;
  bottom: -35px;
  right: -20px;
  background: #50505a;
  color: #fcf2f2;
  width: 320px;
  padding: 16px 4px 16px 0;
  border-radius: 6px;
  font-size: 13px;
  box-shadow: 10px 10px 40px -14px #000;

  display: flex;
  justify-content: center;
  align-items: center;
}
span {
  margin: 0 5px 0 15px;
}

.signIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  margin: 3rem 0 5rem 0;
  padding: 2rem;

  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: row;
}

.image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.zoomed {
  transform: scale(1.2); /* Increase the scale for zoom effect */
}

.zoomIn {
  font-size: 2rem;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.zoomOut {
  font-size: 2rem;
  cursor: pointer;
  z-index: 99999;
  position: relative;
  bottom: 16rem;
  left: 2rem;
}

/* Modal */
.modal {
  display: block;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  overflow: auto;
}

.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.serviceGraphicLeft {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.serviceGraphicRight {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.gdg__gallery {
  text-align: center;
  font-size: 3rem;
}

.three_shirts {
  position: relative;
  bottom: 5rem;
}

.jrome_pair {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.intro {
  margin-top: 8rem;
}

.style {
  font-size: 40px;
}

.welcome {
  font-size: 20px;
}

.owned {
  font-size: 15px;
}

.owned img {
  width: 3rem;
}

.trademark {
  font-size: 15px;
}

.socialMedia {
  font-size: 3rem;
}

.juneteenth {
  width: 700px;
  border-radius: 5px;
}

@media all and (max-width: 480px) {
  .shirts {
    flex-direction: column;
    align-items: center;
  }

  .juneteenth {
    width: 350px;
  }

  .subscribe-content {
    max-width: 80%;
  }

  .btn-subscribe {
    margin-top: 1rem;
    padding: 8px;
    align-self: center;
  }

  .btn__subscribe__container {
    text-align: center;
  }

  .width {
    width: 300px;
    margin: 0 auto 2rem;
  }
  .socialMedia {
    font-size: 1.5rem;
    line-height: -1;
  }

  .owned {
    font-size: 9px;
  }

  .owned img {
    width: 20px;
  }

  .trademark {
    font-size: 9px;
  }

  .graph {
    max-width: 80%;
    max-height: 80%;
  }

  .intro {
    margin-top: 1rem;
  }

  .welcome {
    width: 360px;
    font-size: 18px;
  }

  .jrome_pair {
    flex-direction: column;
    align-items: center;
  }

  .three_shirts {
    flex-direction: column;
    height: 200px;
    width: 200px;
  }

  /* .love_logo {
    max-width: 50%;
    max-width: 50%;
  } */

  .alpha_video {
    display: none;
  }

  /* .app__Email {
    margin: 12rem 0 15rem 0;
  } */

  .logo {
    margin-left: 1rem;
    width: 15rem;
  }

  .serviceGraphic {
    display: flex;
    flex-direction: column;
  }

  .serviceGraphic img {
    width: 150px;
    height: auto;
  }

  .product__image {
    object-fit: cover;
    margin-top: 2rem;
  }

  .zoomIn {
    display: none;
  }

  .zoomOut {
    display: none;
  }

  /* .card__center {
    width: 20rem;
    position: relative;
    left: 10%;
  } */

  .product_screen {
    width: 20rem;
    position: relative;
    left: 13%;
  }

  /* .product_img {
    aspect-ratio: "1/2";
  } */
  .swiper {
    left: 0;
  }

  footer {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-evenly;
    margin: 2rem 1rem 0 1rem;
  }

  .gram {
    height: 20px;
    width: 20px;
    color: black;
  }

  .twitter {
    height: 20px;
    width: 20px;
    color: black;
    margin-left: 5px;
  }

  .fbook {
    height: 20px;
    width: 20px;
    color: black;
  }

  .linkn {
    height: 20px;
    width: 20px;
    color: black;
  }

  .twitt {
    height: 15px;
    width: 15px;
  }

  .zoom__out {
    display: none;
  }

  .zoom__in {
    display: none;
  }

  .about_text {
    margin-bottom: 2rem;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .graph {
    max-width: 80%;
    max-height: 80%;
  }
}
